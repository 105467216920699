import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Table } from 'react-bootstrap';

import '../../generic-page.scss';

const CSSShipCostPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Ship building cost</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/category_shipcost.png"
            alt="Ship building cost"
          />
        </div>
        <div className="page-details">
          <h1>Ship building cost</h1>
          <h2>How much materials you will need to farm to build ships.</h2>
        </div>
      </div>
      <div className="page-content">
        <SectionHeader title="Unique materials" />
        <p>
          What Unique Ship Materials you need to craft and upgrade a ship
          depends on its class:
        </p>
        <ul>
          <li>Cruiser - Ship Charges</li>
          <li>Armored - Heavy Covering</li>
          <li>Auxiliary - Superconductor</li>
          <li>Assault - High-elasticy Armor</li>
        </ul>
        <p>
          Overall, most of the best ships in the game - Enterprise for PVE, Lake
          Superior for PVP and Coffin-6 for Mechs - are classified as Armored
          ships. That's why as a new player, you should get Heavy Covering from
          the free selectors they throw at you in the early game.
        </p>
        <SectionHeader title="N rarity ships" />
        <p>Materials needed to craft N rarity ships:</p>
        <ul>
          <li>50 000 Credits</li>
          <li>100 Ship Materials</li>
          <li>
            1 Unique Ship Material (it's different depending on the ship's
            class)
          </li>
          <li>10 Special Ship Schematics</li>
        </ul>
        <p>Materials needed to upgrade N Rarity ships:</p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Stage</th>
              <th>Credits</th>
              <th>Ship Materials</th>
              <th>Prime Ship Materials</th>
              <th>Unique Ship Materials</th>
              <th>Eternium propellant</th>
              <th>Black box fragment</th>
              <th>Special Ship Schematics</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1-70 level</td>
              <td>244,000</td>
              <td>124</td>
              <td>20</td>
              <td>7</td>
              <td>15</td>
              <td>0</td>
              <td></td>
            </tr>
            <tr>
              <td>1-90 level</td>
              <td>494,000</td>
              <td>124</td>
              <td>60</td>
              <td>13</td>
              <td>15</td>
              <td>7</td>
              <td></td>
            </tr>
            <tr>
              <td>1-100 level</td>
              <td>794,000</td>
              <td>124</td>
              <td>100</td>
              <td>21</td>
              <td>25</td>
              <td>17</td>
              <td></td>
            </tr>
            <tr>
              <td>Total</td>
              <td>844,000</td>
              <td>224</td>
              <td>100</td>
              <td>22</td>
              <td>25</td>
              <td>17</td>
              <td>10</td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="R rarity ships" />
        <p>Materials needed to craft R rarity ships:</p>
        <ul>
          <li>100 000 Credits</li>
          <li>300 Ship Materials</li>
          <li>
            10 Unique Ship Material (it's different depending on the ship's
            class)
          </li>
          <li>30 Special Ship Schematics</li>
        </ul>
        <p>Materials needed to upgrade R Rarity ships:</p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Stage</th>
              <th>Credits</th>
              <th>Ship Materials</th>
              <th>Prime Ship Materials</th>
              <th>Unique Ship Materials</th>
              <th>Eternium propellant</th>
              <th>Black box fragment</th>
              <th>Special Ship Schematics</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1-70 level</td>
              <td>264,000</td>
              <td>159</td>
              <td>20</td>
              <td>9</td>
              <td>20</td>
              <td>0</td>
              <td></td>
            </tr>
            <tr>
              <td>1-90 level</td>
              <td>539,000</td>
              <td>159</td>
              <td>80</td>
              <td>16</td>
              <td>20</td>
              <td>8</td>
              <td></td>
            </tr>
            <tr>
              <td>1-100 level</td>
              <td>889,000</td>
              <td>159</td>
              <td>130</td>
              <td>26</td>
              <td>30</td>
              <td>20</td>
              <td></td>
            </tr>
            <tr>
              <td>Total</td>
              <td>989,000</td>
              <td>459</td>
              <td>130</td>
              <td>36</td>
              <td>30</td>
              <td>20</td>
              <td>30</td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="SR rarity ships" />
        <p>Materials needed to craft SR rarity ships:</p>
        <ul>
          <li>500 000 Credits</li>
          <li>300 Prime Ship Materials</li>
          <li>
            30 Unique Ship Material (it's different depending on the ship's
            class)
          </li>
          <li>40 Special Ship Schematics</li>
        </ul>
        <p>Materials needed to upgrade SR Rarity ships:</p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Stage</th>
              <th>Credits</th>
              <th>Ship Materials</th>
              <th>Prime Ship Materials</th>
              <th>Unique Ship Materials</th>
              <th>Eternium propellant</th>
              <th>Black box fragment</th>
              <th>Special Ship Schematics</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1-70 level</td>
              <td>493,000</td>
              <td>208</td>
              <td>40</td>
              <td>15</td>
              <td>25</td>
              <td>10</td>
              <td></td>
            </tr>
            <tr>
              <td>1-90 level</td>
              <td>1,043,000</td>
              <td>208</td>
              <td>120</td>
              <td>25</td>
              <td>25</td>
              <td>10</td>
              <td></td>
            </tr>
            <tr>
              <td>1-100 level</td>
              <td>1,643,000</td>
              <td>208</td>
              <td>180</td>
              <td>40</td>
              <td>35</td>
              <td>25</td>
              <td>10</td>
            </tr>
            <tr>
              <td>Total</td>
              <td>2,143,000</td>
              <td>208</td>
              <td>480</td>
              <td>70</td>
              <td>35</td>
              <td>25</td>
              <td>40</td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="SSR rarity ships" />
        <p>Materials needed to craft SSR rarity ships:</p>
        <ul>
          <li>1 000 000 Credits</li>
          <li>500 Prime Ship Materials</li>
          <li>
            50 Unique Ship Material (it's different depending on the ship's
            class)
          </li>
          <li>100 Special Ship Schematics</li>
        </ul>
        <p>Materials needed to upgrade SSR Rarity ships:</p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Stage</th>
              <th>Credits</th>
              <th>Ship Materials</th>
              <th>Prime Ship Materials</th>
              <th>Unique Ship Materials</th>
              <th>Eternium propellant</th>
              <th>Black box fragment</th>
              <th>Special Ship Schematics</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1-15 level</td>
              <td>42,000</td>
              <td>25</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>1* &#62; 2*</td>
              <td>25,000</td>
              <td></td>
              <td></td>
              <td>3</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>16-30 level</td>
              <td>75,000</td>
              <td>75</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>2* &#62; 3*</td>
              <td>50,000</td>
              <td></td>
              <td></td>
              <td>7</td>
              <td>12</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>31-50 level</td>
              <td>200,000</td>
              <td>140</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>3* &#62; 4*</td>
              <td>125,000</td>
              <td></td>
              <td></td>
              <td>12</td>
              <td>20</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>51-70 level</td>
              <td>400,000</td>
              <td></td>
              <td>40</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>4* &#62; 5*</td>
              <td>250,000</td>
              <td></td>
              <td></td>
              <td>18</td>
              <td></td>
              <td>12</td>
              <td></td>
            </tr>
            <tr>
              <td>71-90 level</td>
              <td>500,000</td>
              <td></td>
              <td>100</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>5* &#62; 6</td>
              <td>500,000</td>
              <td></td>
              <td></td>
              <td>25</td>
              <td></td>
              <td>20</td>
              <td></td>
            </tr>
            <tr>
              <td>91-100 level</td>
              <td>500,000</td>
              <td></td>
              <td>70</td>
              <td></td>
              <td>10</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Total</td>
              <td>3,667,000</td>
              <td>240</td>
              <td>710</td>
              <td>115</td>
              <td>42</td>
              <td>32</td>
              <td>100</td>
            </tr>
          </tbody>
        </Table>
        <p>
          Source of the information:{' '}
          <a
            href="https://cafe.naver.com/csunofficialfancafe?iframe_url_utf8=%2FArticleRead.nhn%253Fclubid%3D30062553%2526page%3D8%2526menuid%3D2%2526boardtype%3DL%2526articleid%3D7713%2526referrerAllArticles%3Dfalse"
            target="_blank"
            rel="noreferrer"
          >
            Cafe Naver
          </a>
        </p>
      </div>
    </DashboardLayout>
  );
};

export default CSSShipCostPage;

export const Head: React.FC = () => (
  <Seo
    title="Ship building cost | Counter Side | Prydwen Institute"
    description="How much materials you will need to farm to build ships."
  />
);
